import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Subspflookup from './subspflookup';



function Spflookup(props) {


    if (props.includes.length >= 1 ) {
        console.log(props.includes);
        return (

            <React.Fragment>
<br></br>
                {(props.includes).map((include, i) => (

 (include).map((domain, i) => (


                    <div key={i}>


                        <Accordion sx={{ zIndex:1 }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography><b>{domain['domain']}</b>  (DNS lookups : {domain['dns_lookups'] })</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          {domain['record']}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      { 
                      domain.parsed.include.length >= 1 &&  <Subspflookup includes={domain.parsed.include}/>
                    }
                        </div>


                    ))





                    

                ))}
                

            </React.Fragment>

        )
    }
       
}

export default Spflookup;