import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, matchPath } from "react-router-dom";
import DmarcTab from './dmarctab'; /* dmarc record display tab component import*/
import SpfTab from './spftab'; /* spf record display tab component import*/
import MxTab from './mxtab'; /* mx record display tab component import*/
import NsTab from './nstab'; /* ns record display tab component import*/
import TxtTab from './txttab'; /* txt record display tab component import*/
import ArecordTab from './arecordtab'; /* a records display tab component import*/
import ReversednsTab from './reversednsrecordtab'; /* reverse dns records display tab component import*/
import BimiTab from './bimitab'; /*  bimi record display tab component import*/
import BlacklistTab from './blacklisttab'; /*  ip blacklist display tab component import*/
import BlacklistdomainTab from './blacklistdomaintab'; /*  domain blacklist display tab component import*/
import CheckForm from './checkform'; /* dns check record form component import  */


function ChecksLogics() {

/* Variable and function to analyze url path for domain parameter and checktype (dmarc, spf) in url path. When a domain parameter is seen in url then Submit request function with that domain is trigerred */
    const useQuery = () => new URLSearchParams(useLocation().search); 
    const query = useQuery();
    const domaininurl = query.get('domain');
    const pathname = window.location.pathname

    useEffect(() => {

        if (domaininurl) {
            console.log(domaininurl);
            SubmitRequest(domaininurl);

        }
    }, [domaininurl,pathname]);


    /* dmarc check state variables */
    const [dmarcrecord, setDmarcrecord] = useState('');
    const [dmarcrecordlocation, setDmarcrecordlocation] = useState('');
    const [p, setP] = useState('');
    const [sp, setSp] = useState('');
    const [pct, setPct] = useState('');
    const [rua, setRua] = useState([]);
    const [ruf, setRuf] = useState([]);
    const [ns, setNs] = useState([]);
    const [dmarcrecordpresent, setDmarcrecordpresent] = useState(false);


     /* SPF check state variables */

     const [alldnsrecords, setAlldnsrecords] = useState([]);
     const [spfrecord, setSPFcrecord] = useState('');
     const [spfdnslookups, setSpfDnsLookups] = useState('no');
     const [includes, setIncludes] = useState([]);
     const [spfrecordpresent, setSPFrecordpresent] = useState(false);
     const [spferror, setSpferror] = useState('');

   /* mx check state variables */

     const [mxrecords, setMxrecords] = useState([]);
     const [mxrecordpresent, setMxrecordpresent] = useState(false);

    /* ns check state variables */

    const [nsrecords, setNsrecords] = useState([]);
    const [nsrecordpresent, setNsrecordpresent] = useState(false);


    /* txt check state variables */

    const [txtrecords, setTxtrecords] = useState([]);
    const [txtrecordpresent, setTxtrecordpresent] = useState(false);


     /* a record check state variables */

    const [arecords, setArecords] = useState([]);
    const [arecordpresent, setArecordpresent] = useState(false);

      /* reverse dns record check state variables */

      const [reversednsrecords, setReversednsrecords] = useState([]);
      const [reversednsrecordpresent, setReversednsrecordpresent] = useState(false);   


      /* reverse bimi record check state variables */

      const [bimirecords, setBimirecords] = useState([]);
      const [bimirecordpresent, setBimirecordpresent] = useState(false);   

      /* ip blacklist check state variables */

      const [ipblacklists, setIpblacklists] = useState([]);
      const [ipblacklistpresent, setIpblacklistpresent] = useState(false);

       /* domain blacklist check state variables */

       const [domainblacklists, setDomainblacklists] = useState([]);
       const [domainblacklistpresent, setDomainblacklistpresent] = useState(false);
          
    
/* state variable used by the form component as a props for the button spinner icon and to know if the form was submitted */
  
    const [loading, setLoading] = React.useState(false);
    const [submitedform, setSubmitedform] = useState(false);
    
/* function to request data depending for the check displayed in the path url (dmarc, spf, etc) */

    const SubmitRequest = async (domainrequest) => {
        
        setLoading(true);
        setSubmitedform(false);

        if(pathname.includes("dmarc%20record")){ /* begining of dmarc check request and data harvest */

        const response = await axios.get('mailing-dns-records', { params: { d: domainrequest } });

        setAlldnsrecords([]);
        setDmarcrecord('');
        setDmarcrecordlocation('');
        setPct('');
        setP('');
        setSp('');
        setRua([]);
        setRuf([]);
        setNs([]);
        setDmarcrecordpresent(false);
        

        if (response.data.dmarc.record != null) {

            console.log(response.data.dmarc.record);
            setDmarcrecord(response.data.dmarc.record);
            setDmarcrecordlocation(response.data.dmarc.location);
            setPct(response.data.dmarc.tags.pct.value);
            setP(response.data.dmarc.tags.p.value);
            setSp(response.data.dmarc.tags.sp.value);
            try {
                setRua(response.data.dmarc.tags.rua.value);
            } catch (error) { setRua([]) }

            try {
                setRuf(response.data.dmarc.tags.ruf.value);
            } catch (error) { setRuf([]) }

            setNs(response.data.ns.hostnames);

            setDmarcrecordpresent(true);
            setLoading(false);
        }
        else {
            setDmarcrecord('No DMARC record');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of dmarc check request and data harvest */


    if(pathname.includes("spf%20record")){ /* begining of spf check request and data harvest */

        const response = await axios.get('mailing-dns-records', { params: { d: domainrequest } });

        setAlldnsrecords([]);
        setSPFcrecord('');
        setSpfDnsLookups('no');
        setIncludes([]);
        setSPFrecordpresent(false);
        setSpferror('');
        setSubmitedform(false);

console.log(response.data.spf);
        if (response.data.spf.record != null) {

            try {
                setSpferror(response.data.spf.error);
                console.log(response.data.spf.error);
            } catch (error) { setSpferror('') }


            try {
                setSPFcrecord(response.data.spf.record);
            } catch (error) { setSPFcrecord('') }
            

            try {
                setSpfDnsLookups(response.data.spf.dns_lookups);
            } catch (error) { setSpfDnsLookups('no') }
            
            
            /* add try here */

            try {
                setIncludes([response.data.spf.parsed.include]);
            } catch (error) { setIncludes([]) }
            
            setSPFrecordpresent(true);
            setLoading(false);
        }
        else {
            setSPFcrecord('No SPF record');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of SPF check request and data harvest */





    if(pathname.includes("mx%20record")){ /* begining of MX check request and data harvest */

        const response = await axios.get('mailing-dns-records', { params: { d: domainrequest } });

        setMxrecords([]);
        setMxrecordpresent(false);
        setSubmitedform(false);

console.log(response.data.mx.hosts);
        if (response.data.mx.hosts.length >= 1) {

            try {
                setMxrecords([response.data.mx.hosts]);
            } catch (error) { setMxrecords([]) }

            setMxrecordpresent(true);
            setLoading(false);
        }
        else {
            setMxrecords('No MX record');
            setMxrecordpresent(false);
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of MX check request and data harvest */




    if(pathname.includes("ns%20record")){ /* begining of NS check request and data harvest */

        const response = await axios.get('mailing-dns-records', { params: { d: domainrequest } });

        setNsrecords([]);
        setNsrecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data.ns.hostnames);
        if (response.data.ns.hostnames != null) {

            try {
                setNsrecords([response.data.ns.hostnames]);
            } catch (error) { setNsrecords([]) }

            setNsrecordpresent(true);
            setLoading(false);
        }
        else {
            setNsrecords('No NS record');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of Ns check request and data harvest */




    if(pathname.includes("txt%20record") || pathname.includes("dkim")){ /* begining of txt check request and data harvest */

        const response = await axios.get('mailing-dns-txtrecord', { params: { d: domainrequest } });

        setTxtrecords([]);
        setTxtrecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != null) {

            try {
                setTxtrecords([response.data]);
            } catch (error) { setTxtrecords([]) }

            setTxtrecordpresent(true);
            setLoading(false);
        }
        else {
            setTxtrecords('No TXT record');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of txt check request and data harvest */



    if(pathname.includes("a%20record")) { /* begining of txt check request and data harvest */

        const response = await axios.get('mailing-dns-arecord', { params: { d: domainrequest } });

        setArecords([]);
        setArecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != null) {

            try {
                setArecords([response.data]);
            } catch (error) { setArecords([]) }

            setArecordpresent(true);
            setLoading(false);
        }
        else {
            setArecords('No A record for this hostname');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of a records check request and data harvest */


    if(pathname.includes("reverse%20dns")) { /* begining of txt check request and data harvest */

        const response = await axios.get('mailing-dns-reversednsrecord', { params: { d: domainrequest } });

        setReversednsrecords([]);
        setReversednsrecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != null) {

            try {
                setReversednsrecords([response.data]);
            } catch (error) { setReversednsrecords([]) }

            setReversednsrecordpresent(true);
            setLoading(false);
        }
        else {
            setReversednsrecords('No A record for this hostname');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of a records check request and data harvest */



    if(pathname.includes("a%20record")) { /* begining of txt check request and data harvest */

        const response = await axios.get('mailing-dns-arecord', { params: { d: domainrequest } });

        setArecords([]);
        setArecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != null) {

            try {
                setArecords([response.data]);
            } catch (error) { setArecords([]) }

            setArecordpresent(true);
            setLoading(false);
        }
        else {
            setArecords('No A record for this hostname');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of a records check request and data harvest */


    if(pathname.includes("bimi%20record")) { /* begining of bimi check request and data harvest */

        const response = await axios.get('mailing-dns-bimidnsrecord', { params: { d: domainrequest } });

        setBimirecords([]);
        setBimirecordpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != null) {

            try {
                setBimirecords([response.data]);
            } catch (error) { setBimirecords([]) }

            setBimirecordpresent(true);
            setLoading(false);
        }
        else {
            setBimirecords('No A record for this hostname');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of bimi record check request and data harvest */



    if(pathname.includes("ip%20blacklist")) { /* begining of ip blacklist request and data harvest */

        const response = await axios.get('mailing_dns_blacklistip', { params: { d: domainrequest } });

        setIpblacklists([]);
        setIpblacklistpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != 'false') {

            try {
                setIpblacklists(response.data);
            } catch (error) { setIpblacklists([]) }

            setIpblacklistpresent(true);
            setLoading(false);
        }
        else {
            setIpblacklists('This IP is not blacklisted');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of ip blacklist request and data harvest */


    if(pathname.includes("domain%20blacklist")) { /* begining of domain blacklist request and data harvest */

        const response = await axios.get('mailing_dns_blacklistdomain', { params: { d: domainrequest } });

        setIpblacklists([]);
        setIpblacklistpresent(false);
        setSubmitedform(false);
    

console.log(response.data);
        if (response.data != 'false') {

            try {
                setDomainblacklists(response.data);
            } catch (error) { setDomainblacklists([]) }

            setDomainblacklistpresent(true);
            setLoading(false);
        }
        else {
            setDomainblacklists('This Domain is not blacklisted');
            setLoading(false);

        }

        setSubmitedform(true);
        setLoading(false);
    }  /* end of domain blacklist request and data harvest */





}









    return (


        <main className="container">

            <div className="my-3 p-5 bg-body rounded shadow-sm">

                <CheckForm loading={loading} domaininurl={domaininurl} pathname={pathname} />

                { 
                    pathname.includes("dmarc%20record") &&  

                    <DmarcTab dmarcrecord={dmarcrecord} dmarcrecordlocation={dmarcrecordlocation} p={p} sp={sp} pct={pct} rua={rua} ruf={ruf} submitedform={submitedform} ns={ns} dmarcrecordpresent={dmarcrecordpresent} />
                }

{ 
                    pathname.includes("spf%20record") &&  

                    <SpfTab spfrecord={spfrecord} spferror={spferror} includes={includes} spfrecordpresent={spfrecordpresent} submitedform={submitedform} spfdnslookups={spfdnslookups}/>
                }

{ 
                    pathname.includes("mx%20record") &&  

                    <MxTab mxrecords={mxrecords} mxrecordpresent={mxrecordpresent} submitedform={submitedform} />
                }

{ 
                    pathname.includes("ns%20record") &&  

                    <NsTab nsrecords={nsrecords} nsrecordpresent={nsrecordpresent} submitedform={submitedform} />
                }

{ 
                    (pathname.includes("txt%20record") || pathname.includes("dkim")) &&  

                    <TxtTab txtrecords={txtrecords} txtrecordpresent={txtrecordpresent} submitedform={submitedform} />
                }
                { 
                    pathname.includes("a%20record") &&  

                    <ArecordTab arecords={arecords} arecordpresent={arecordpresent} submitedform={submitedform} />
                }
                { 
                    pathname.includes("reverse%20dns") &&  

                    <ReversednsTab reversednsrecords={reversednsrecords} reversednsrecordpresent={reversednsrecordpresent} submitedform={submitedform} />
                }
                 { 
                    pathname.includes("bimi%20record") &&  

                    <BimiTab bimirecords={bimirecords} bimirecordpresent={bimirecordpresent} submitedform={submitedform} />
                }  

                                 { 
                    pathname.includes("ip%20blacklist") &&  

                    <BlacklistTab ipblacklists={ipblacklists} ipblacklistpresent={ipblacklistpresent} submitedform={submitedform} />
                }

                                  { 
                    pathname.includes("domain%20blacklist") &&  

                    <BlacklistdomainTab domainblacklists={domainblacklists} domainblacklistpresent={domainblacklistpresent} submitedform={submitedform} />
                }                                     
            </div>
        </main>


    );
}
export default ChecksLogics;
