import React from 'react';
import {
    Box
} from "@mui/material/";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';






function BlacklistdomainTab(props) {





    if ((props.submitedform != false) && (props.domainblacklistpresent)) {

        console.log(props.domainblacklists);

        
            return (

            <React.Fragment>
<br></br>
                <TableContainer component={Paper}>
                    <Table sx={{ Width: '50%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><b>Blacklist provider</b></TableCell>
                                <TableCell align="center"><b>Blacklist names</b></TableCell>
                                <TableCell align="center"><b>Blacklist Categories</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>




                        {
                        
                        Object.entries(props.domainblacklists).map( ([key, value]) => (
                        
                        <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                    <TableCell component="th" scope="row">
                            <b>
                            <a href={'http://www.'+ key.split('.').reverse().splice(0,2).reverse().join('.')} target="_blank">{key.split('.').reverse().splice(0,2).reverse().join('.')}</a>


                        </b>
                            
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {key}

                        </TableCell>
                        <TableCell align="center">
                            
                        {   
                        (value).map((category, i) => (
                            
                            <div key={i}>
                            {category}
                        

                        </div>
                            
                            
                        ))}
                            
                            
                            </TableCell>

                    </TableRow>









                        
                        
                        
                        )
                        
                        
                        
                        
                        
                        )

    






}
 



    


   













                        </TableBody>
                    </Table>
                </TableContainer>































            </React.Fragment>

        )
    } else if ((props.domainblacklistpresent == false) && (props.submitedform == true)) {

        return (

            <React.Fragment>
                <br></br>
                <h4>Not blacklisted</h4>
            </React.Fragment>

        );
    }
    else if (props.submitedform == false) {

        return (

            <React.Fragment>
            </React.Fragment>

        );
    }
}

export default BlacklistdomainTab;