import React from 'react';
import './App.css';
import Nav from './components/Nav'
import ChecksLogics from './components/checks/checkslogics';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";


function App() {
  return (

    <div className="App">



      <Nav />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ChecksLogics />} />
          <Route path="/dmarc%20record" element={<ChecksLogics />} />
          <Route path="/spf%20record" element={<ChecksLogics />} />
          <Route path="/mx%20record" element={<ChecksLogics />} />
          <Route path="/ns%20record" element={<ChecksLogics />} />
          <Route path="/txt%20record" element={<ChecksLogics />} />
          <Route path="/dkim%20record" element={<ChecksLogics />} />
          <Route path="/a%20record" element={<ChecksLogics />} />
          <Route path="/reverse%20dns" element={<ChecksLogics />} />
          <Route path="/bimi%20record" element={<ChecksLogics />} />
          <Route path="/ip%20blacklist" element={<ChecksLogics />} />
          <Route path="/domain%20blacklist" element={<ChecksLogics />} />
        </Routes>
      </BrowserRouter>


    </div>




  );
}

export default App;
