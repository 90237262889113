import React from 'react';
import {
    Box
} from "@mui/material/";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



function TxtTab(props) {




    if ((props.submitedform != false) && (props.txtrecordpresent)) {

        console.log(props.txtrecords);

        return (

            <React.Fragment>
<br></br>
                <TableContainer component={Paper}>
                    <Table sx={{ Width: '50%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><b>Txt record(s)</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>





                            {(props.txtrecords).map((txtrecords, i) => (

                                (txtrecords).map((txtrecord, i) => (

                                 
                                        <TableRow

                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">
                                                {txtrecord}
                                            </TableCell>
                                        </TableRow>


                                   


                                ))







                            ))}









                        </TableBody>
                    </Table>
                </TableContainer>































            </React.Fragment>

        )
    } else if ((props.txtrecordpresent == false) && (props.submitedform == true)) {

        return (

            <React.Fragment>
                <br></br>
                <h4>No TXT record</h4>
            </React.Fragment>

        );
    }
    else if (props.submitedform == false) {

        return (

            <React.Fragment>
            </React.Fragment>

        );
    }
}

export default TxtTab;